import React from 'react'
import posed from 'react-pose'
import { LineBreak } from '../StyledComponents'

const WrappedLineBreak = (props) => <LineBreak {...props} />

export const AnimatedLineBreak = posed(WrappedLineBreak)({
  initial: {
    width: 0,
    opacity: 0,
  },
  enter: {
    width: '75px',
    opacity: 1,
  }
})

export const Stagger = posed.div({
  initial: {
    staggerChildren: 0,
  },
  enter: {
    staggerChildren: ({ speed }) => speed || 450,
    transition: {
      delay: ({ delay }) => delay || 0,
    }
  }
})

export const Fade = posed.div({
  initial: {
    opacity: 0
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 600,
    }
  }
})

export const FadeUp = posed.div({
  initial: {
    opacity: 0,
    y: '100%',
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 300,
      delay: ({ delay }) => delay || 0,
    }
  }
})

export { default as FadeIn } from './FadeIn'
export { default as StaggerChildren } from './StaggerChildren'