import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { css } from 'styled-components'
import posed from 'react-pose'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { FadeIn } from '../components/PosedComponents'

import { QuoteCard } from "../components/Card/QuoteCard"

import ChooseIcon from '../assets/icons/choose.svg'

import config from '../utils/siteConfig'
import { media } from '../utils/media'
import { space, mediaQueries, breakpoints } from '../utils/tokens';

const Stagger = posed.div({
  enter: {
    staggerChildren: 250,
  },
  exit: {
    staggerChildren: 100,
  }
})

const GetAQuote = ({ data, location }) => {

  const [loaded, setLoaded] = useState(true)

  useEffect(() => {
    setLoaded(false)
  }, [])

  const { hero, slug, title } = data.contentfulPage
  const postNode = data.contentfulPage
  return (
    <div>
      <Layout location={location}>
        <SEO pagePath={slug} postNode={postNode} pageSEO />
        <Helmet>
          <title>{`${title} - ${config.siteTitle}`}</title>
        </Helmet>
        <div
          css={css({
            [mediaQueries.lg]: {
              padding: `${space[6]}px ${space[6]}px`,
            },
            [mediaQueries.xxl]: {
              padding: `${space[5]}px ${space[6]}px`,
            },
          })}
        >
          <div
            css={css({
              position: `relative`,
              [mediaQueries.lg]: {
                minHeight: `calc(100vh - ${space[6]})px`,
                display: `grid`,
                gridTemplateColumns: `2fr 3fr`,
                alignContent: `center`,
                maxWidth: breakpoints.xxl,
                margin: `0 auto`,
              },
            })}
          >
            <div
              css={css({
                position: `absolute`,
                top: 0,
                right: 0,
                [mediaQueries.lg]: {
                  bottom: `50%`,
                  top: `50%`,
                  left: `0`,
                  right: `unset`,
                  transform: `translateX(-50%)`,
              }})}
            >
              <Stagger pose={loaded ? 'exit' : 'enter'}>
                <FadeIn direction="left">
                  <ChooseIcon
                    css={css`
                      opacity: 1;
                      width: 150px;
                      height: auto;

                      ${media.desktop`
                  width: 300px;
                `}
                    `}
                  />
                </FadeIn>
              </Stagger>
            </div>
            <section
              css={css({
                position: `relative`,
                padding: `${space[6]}px ${space[4]}px 0`,
              })}
            >
              <Stagger pose={loaded ? 'exit' : 'enter'}>
                <FadeIn direction="right">
                  <h1
                    css={css`
                      ${media.desktop`
                  font-size: 3rem;
                `}
                    `}
                  >
                    {hero.title}
                  </h1>
                </FadeIn>
                <FadeIn direction="up">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: hero.subtitle.childMarkdownRemark.html,
                    }}
                    css={css`
                      margin-top: 1rem;
                      font-size: 1.2rem;
                    `}
                  />
                </FadeIn>
              </Stagger>
            </section>
            <section
              css={css`
                padding: 2rem 2rem;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-auto-rows: 100px;
                grid-gap: ${space[3]}px;
                ${media.desktop`
                  display: grid;
                  grid-template-columns: repeat(3, 1fr);
                  grid-auto-rows: 150px;
                  grid-gap: ${space[3]}px;
                  align-content: center;
              `}
              `}
            >
              {data.allContentfulService.edges.map((service, index) => {
                return (
                  <div
                    key={index}
                    css={css`
                      transform: scale(1);
                      transition-duration: 300ms;
                      overflow: hidden;
                      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);

                      ${media.desktop`
                        margin-bottom: 0;
                        padding: 0;
                        height: 100%;
                      `}
                    `}
                  >
                    <QuoteCard
                      icon={service.node.icon.file.url}
                      title={service.node.name}
                      typeform={service.node.typeform}
                    />
                  </div>
                )
              })}
            </section>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default GetAQuote

export const GetAQuoteQuery = graphql`
  query GetAQuoteQuery {
    contentfulPage(slug: { eq: "get-a-quote" }) {
      hero {
        ... on Node {
          ... on ContentfulHero {
            title
            subtitle {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
    }

    allContentfulService(sort: { fields: [name] }) {
      edges {
        node {
          slug
          typeform
          ...ServiceCardFragment_withDescription
          fields {
            path
          }
        }
      }
    }
  }
`
