import React, { Component } from 'react'
import PropTypes from 'prop-types'
import posed from 'react-pose'
import Waypoint from 'react-waypoint'

const delay = 0
const duration = 500

const FadeUp = posed.div({
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      delay: delay,
      duration: duration,
    }
  },
  exit: {
    opacity: 0,
    y: '25%',
  }
})

const FadeRight = posed.div({
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      delay: delay,
      duration: duration,
    }
  },
  exit: {
    opacity: 0,
    x: '-50%',
  }
})

const FadeLeft = posed.div({
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      delay: delay,
      duration: duration
    }
  },
  exit: {
    opacity: 0,
    x: '50%'
  }
})

const Fade = posed.div({
  enter: {
    opacity: 1,
    transition: {
      delay: delay,
      duration: duration,
    }
  },
  exit: {
    opacity: 0
  }
})

class FadeIn extends Component {
  state = {
    animate: false,
    entered: true,
  }

  handleEnter = () => {
    this.setState({
      animate: true,
      entered: true,
    })
  }

  handleExit = () => {
    this.setState({
      animate: false,
      entered: false,
    })
  }

  render() {
    const { animate, entered } = this.state
    const { waypoint, direction } = this.props

    let PoseComponent

    const pose = entered && (animate ? 'enter' : 'exit')

    switch (direction) {
      case 'up':
        PoseComponent = <FadeUp pose={pose}>{this.props.children}</FadeUp>
        break
      case 'right':
        PoseComponent = <FadeRight pose={pose}>{this.props.children}</FadeRight>
        break
      case 'left':
        PoseComponent = <FadeLeft pose={pose}>{this.props.children}</FadeLeft>
        break
      default:
        PoseComponent = <Fade pose={pose}>{this.props.children}</Fade>
    }

    return (
      waypoint ?
        (
          <Waypoint {...this.props.waypointConfig} onEnter={this.handleEnter} onLeave={this.handleExit}>
            {PoseComponent}
          </Waypoint>
        ) : (
          PoseComponent
        )
    )
  }
}

FadeIn.propTypes = {
  children: PropTypes.node.isRequired,
  waypoint: PropTypes.bool,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right', null]),
  poseConfig: PropTypes.object,
  waypointConfig: PropTypes.object,
}

FadeIn.defaultProps = {
  waypoint: false,
  poseConfig: null,
}

export default FadeIn