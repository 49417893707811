import React, { Component } from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import { css } from 'styled-components'

import { media } from '../../utils/media'

import { ServiceCard } from './ServiceCard'
import { fontSizes, space } from '../../utils/tokens'

export class QuoteCard extends Component {
  constructor(props) {
    super(props);
    this.openForm = this.openForm.bind(this);
  }

  openForm() {
    this.typeformEmbed.typeform.open();
  }

  render() {
    return (
      <div
        css={css`
          position: relative;
          height: 100%;
          background-color: white;
        `}
      >
        <button
          onClick={this.openForm}
          css={css({
            cursor: 'pointer',
            height: `100%`,
            width: `100%`,
            border: `none`,
            backgroundColor: `unset`,
            padding: 0,
          })}>
          <ServiceCard
            icon={this.props.icon}
            title={this.props.title}
            hasHover
            titleCss={css`
              font-size: 1rem;
              margin-bottom: ${space[3]}px;
              ${media.desktop`
                text-align: left;
                font-size: ${fontSizes[2]};
              `}
            `}
            layoutCss={css`
              ${media.desktop`
                justify-content: flex-end;
              `}
            `}
            
          />
        </button>
        <ReactTypeformEmbed
          popup
          autoOpen={false}
          url={this.props.typeform}
          hideHeaders
          hideFooter
          style={{ display: `none` }}
          ref={tf => (this.typeformEmbed = tf)}
        />
      </div>
    )
  }
}