import React, {Component} from 'react'
import PropTypes from 'prop-types'
import posed from 'react-pose'
import Waypoint from 'react-waypoint'

const StaggerChildrenPosed = posed.div({
  enter: {
    staggerChildren: ({ duration }) => {
      return duration || 200
    },
    staggerDirection: ({ staggerDirection }) => { 
      return staggerDirection
    } , 
  },
  exit: {
    staggerChildren: 400
  }
})

class StaggerChildren extends Component {
  state = {
    animate: false,
    entered: true
  }

  handleEnter = () => {
    this.setState({animate: true, entered: true})
  }

  handleExit = () => {
    this.setState({animate: false, entered: false})
  }

  render() {
    const {animate, entered} = this.state
    const {waypoint, waypointConfig, reversed, duration} = this.props

    return (waypoint
      ? (
        <Waypoint
          onEnter={this.handleEnter}
          onLeave={this.handleExit}
          {...waypointConfig}>
          <StaggerChildrenPosed
            pose={entered && (animate
            ? 'enter'
            : 'exit')}
            staggerDirection={reversed ? -1 : 1}
            duration={duration}
          >
            {this.props.children}
          </StaggerChildrenPosed>
        </Waypoint>
      )
      : (
        <StaggerChildren pose={this.props.pose}>{this.props.children}</StaggerChildren>
      ))
  }
}

StaggerChildren.propTypes = {
  children: PropTypes.node.isRequired,
  waypoint: PropTypes.bool,
  waypointConfig: PropTypes.object,
  reversed: PropTypes.bool,
}

StaggerChildren.defaultProps = {
  waypointConfig: {
    topOffset: undefined,
    bottomOffset: undefined
  },
  reversed: false,
}

export default StaggerChildren